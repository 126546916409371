import React from "react";
import { ValidatorComponent } from "react-material-ui-form-validator";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import { TextField } from "@mui/material";

class TextValidatorElement extends ValidatorComponent {
  renderValidatorComponent() {
    const {
      classes,
      errorMessages,
      validators,
      requiredError,
      errorText,
      helperText,
      validatorListener,
      resetValue,
      id,
      label,
      addMargin,
      infoLabel,
      ...rest
    } = this.props;
    const { isValid } = this.state;
    return (
      <>
        <FormControl fullWidth margin="normal">
          <InputLabel
            htmlFor={""}
            className={classNames(!isValid && classes.labelError)}
            style={{ top: "-25px", left: "-12px" }}
          >
            {label}
          </InputLabel>
          <TextField
            {...rest}
            classes={{
              input: addMargin ? classes.inputMargin : null
            }}
          />
          <FormHelperText className={classNames(classes.info)}>
            {infoLabel}
          </FormHelperText>
          <FormHelperText className={classNames(!isValid && classes.error)}>
            {this.errorText(helperText)}
          </FormHelperText>
        </FormControl>
      </>
    );
  }

  errorText(helperText = "") {
    const { isValid } = this.state;
    if (isValid) {
      return helperText;
    }

    return <span>{this.getErrorMessage()}</span>;
  }
}

const styles = theme => ({
  error: {
    marginLeft: "0px",
    color: "#f44336"
  },
  labelError: {
    color: "#f44336"
  },
  inputMargin: {
    marginTop: 20
  },
  info: {
    marginLeft: "0px",
    color: "#000",
    fontWeight: "500"
  }
});

export default withStyles(styles, { withTheme: true })(TextValidatorElement);
