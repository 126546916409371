import React from "react";
import { ValidatorComponent } from "react-material-ui-form-validator";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import { Grid, Radio } from "@mui/material";
import Typography from "@mui/material/Typography";
import TextValidatorElement from "./TextValidatorElement";

class RadioButtonValidatorElement extends ValidatorComponent {
  renderValidatorComponent() {
    const {
      classes,
      errorMessages,
      validators,
      requiredError,
      errorText,
      helperText,
      validatorListener,
      resetValue,
      id,
      label,
      addMargin,
      infoLabel,
      value,
      handleRadioChange,
      handleChange,
      amountExcludeGst,
      NumberFormatCustom,
      radioType,
      ...rest
    } = this.props;
    const { isValid } = this.state;
    return (
      <>
        <FormControl fullWidth margin="">
          
          <label
            htmlFor=""
            style={{ top: "-25px", left: "-12px" }}
            className={classNames(!isValid && classes.labelError)}
          >
            {label}
          </label>
          
          <Grid container rowSpacing={1} columnSpacing={3} xs={8} item>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
              <Radio
                //   disabled={readOnly}
                {...rest}
                checked={value == true}
                onChange={event => {
                  handleRadioChange(event, radioType);
                }}
                value={true}
                name="gstIndicator"
                color="primary"
              />
              <Typography sx={{ fontSize: "12px", fontWeight: 500 }}>
                Yes
              </Typography>
              <Radio
                //   disabled={readOnly}
                {...rest}
                checked={value == false}
                onChange={event => {
                  handleRadioChange(event, radioType);
                }}
                value={false}
                name="gstIndicator"
                color="primary"
              />
              <Typography sx={{ fontSize: "12px", fontWeight: 100 }}>
                No
              </Typography>

              {value && radioType == "gst_indicator" ? (
                <Grid xs={15} marginLeft={20}>
                  <TextValidatorElement
                    {...rest}
                    disabled={true}
                    autoComplete="[off]"
                    fullWidth
                    className={classes.textField}
                    variant="standard"
                    label="Amount Excluding GST"
                    onChange={() => handleChange("amount_exclude_gst")}
                    name="amount_exclude_gst"
                    value={amountExcludeGst}
                    inputComponent={NumberFormatCustom}
                    validators={["required", "notNegative"]}
                    errorMessages={[
                      "Required",
                      "Only numbers and decimal point (.) are allowed in this field. Please remove any other special characters and alphabets."
                    ]}
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <FormHelperText className={classNames(classes.info)}>
            {infoLabel}
          </FormHelperText>
          <FormHelperText className={classNames(!isValid && classes.error)}>
            {this.errorText(helperText)}
          </FormHelperText>
        </FormControl>
      </>
    );
  }

  errorText(helperText = "") {
    const { isValid } = this.state;
    if (isValid) {
      return helperText;
    }

    return <span>{this.getErrorMessage()}</span>;
  }
}

const styles = theme => ({
  error: {
    marginLeft: "0px",
    color: "#f44336"
  },
  labelError: {
    color: "#f44336"
  },
  inputMargin: {
    marginTop: 20
  },
  info: {
    marginLeft: "0px",
    color: "#000",
    fontWeight: "500"
  }
});

export default withStyles(styles, { withTheme: true })(
  RadioButtonValidatorElement
);
