import { withStyles } from "@mui/styles";
import React, { useEffect, useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import { injectIntl } from "react-intl";
import NumberFormat from "react-number-format";
import SelectValidatorElement from "../../../../components/form/SelectValidatorElement";
import TextValidatorElement from "../../../../components/form/TextValidatorElement";
import RadioButtonValidatorElement from "../../../../components/form/RadioButtonValidationElement";
import currencies from "../../../../assets/data/currencies";
import Enums from "../../../../utils/Enums";
import PceService from "../../../../services/PceService";
import { nricValidation } from "../../../../utils/nricValidation";

const NumberFormatCustom = ({ onChange, inputRef, ...other }) => {
  return (
    <NumberFormat
      {...other}
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator
      prefix="$"
      decimalScale={2}
    />
  );
};

const AccountNumberFormat = ({ inputRef, ...props }) => (
  <NumberFormat {...props} allowNegative={false} allowLeadingZeros={true} />
);

const ClaimEditPaymentSection = ({
  claim,
  nricDetails,
  isPCEClient,
  setClaim,
  handleChange,
  readOnly,
  shouldHide,
  classes,
  intl
}) => {
  const {
    bank_account_holder_name: bankAccountHolderName,
    bank_account_number: bankAccountNumber,
    bank_branch_id: bankBranchId,
    bank_id: bankId,
    bank_name: bankName,
    bill_amount: billAmount,
    invoice_no: invoiceNo,
    claimant_remarks: claimantRemarks,
    currency,
    payment_method: paymentMethod,
    gst_indicator: gstIndicator,
    amount_exclude_gst: amountExcludeGst,
    medisave_indicator: medisaveIndicator,
    other_insurer_indicator: otherInsurerIndicator
  } = claim;

  const paymentMethodData = [
    {
      label: "GIRO to employee",
      value: Enums.PAYMENT_METHOD.GIRO_TO_EMPLOYEE
    },
    {
      label: "GIRO to company",
      value: Enums.PAYMENT_METHOD.GIRO_TO_COMPANY
    }
  ];

  let defaultPaymentMethod;
  if (nricDetails.length === 9 && nricValidation(nricDetails)) {
    paymentMethodData.push({
      label: "PayNow to employee",
      value: Enums.PAYMENT_METHOD.PAYNOW_TO_EMPLOYEE
    });
    defaultPaymentMethod = Enums.PAYMENT_METHOD.PAYNOW_TO_EMPLOYEE;
  } else {
    defaultPaymentMethod = Enums.PAYMENT_METHOD.GIRO_TO_EMPLOYEE;
  }

  if (paymentMethod === "CHEQUE") {
    paymentMethodData.push({
      label: "Cheque",
      value: Enums.PAYMENT_METHOD.CHEQUE
    });
    defaultPaymentMethod = Enums.PAYMENT_METHOD.CHEQUE;
  }

  const [bankOptions, setBankOptions] = useState([]);
  useEffect(() => {
    const fetchBankOptions = async () => {
      const { data: bankOptions = [] } = await PceService.fetchBankOptions();
      setBankOptions(bankOptions);

      if (readOnly) {
        return; // no need to fetch previous bank details if this is a submitted case.
      }
      // calling this after fetchBankOptions, to get the proper label.
      // taking first object
      const {
        data: [
          { bank_id, bank_account_number, bank_account_holder_name } = {}
        ] = []
      } = await PceService.fetchCurrentUserBankAccount();

      if (!bank_id) {
        return; // when no previous bank details
      }

      const { label: bankName = "" } =
        bankOptions.find(({ value }) => value === bank_id) || {};

      const acceptedBankId = bankName ? bank_id : "";
      setClaim(claim => ({
        ...claim,
        bank_id: acceptedBankId,
        bank_name: bankName,
        bank_account_number,
        bank_account_holder_name
      }));
    };
    fetchBankOptions();
  }, [readOnly, isPCEClient]);

  const calculateExcludeAmount = useMemo(
    () => amount => {
      if (isNaN(amount) || amount === "") {
        return "";
      }

      let excludeAmount = parseFloat(amount);
      excludeAmount = excludeAmount / 1.09;
      return parseFloat(excludeAmount).toFixed(2);
    },
    []
  );

  useEffect(() => {
    if (gstIndicator) {
      let amount = calculateExcludeAmount(billAmount);
      setClaim(claim => ({
        ...claim,
        amount_exclude_gst: amount
      }));
    }
  }, [billAmount, gstIndicator]);

  useEffect(() => {
    if (currency !== "SGD") {
      setClaim(claim => ({
        ...claim,
        gst_indicator: null,
        amount_exclude_gst: null
      }));
    }
  }, [currency]);

  const handleBankIdChange = useMemo(() => {
    const handleRawBankIdChange = handleChange("bank_id");
    const handleBankNameChange = handleChange("bank_name");
    return selected => {
      handleRawBankIdChange(selected);
      if (selected) {
        const target = selected.target.value;
        const { label = "" } =
          bankOptions.find(({ value }) => value === target) || {};
        handleBankNameChange(label);
      } else {
        handleBankNameChange("");
      }
    };
  }, [handleChange, bankOptions]);

  const currencyData = currencies;

  const renderPaymentDetails = (
    shouldHide,
    paymentMethod,
    bankId,
    bankName,
    bankAccountNumber,
    bankAccountHolderName
  ) => {
    switch (paymentMethod) {
      case Enums.PAYMENT_METHOD.GIRO_TO_EMPLOYEE:
      case Enums.PAYMENT_METHOD.GIRO_TO_COMPANY: {
        if (shouldHide) {
          return <div />;
        }
        if (isPCEClient) {
          return (
            <div>
              <Typography color="inherit" key="staticNotes">
                Please provide a copy of{" "}
                {paymentMethod === "GIRO_TO_COMPANY" ? "the company's" : "your"}{" "}
                bank account statement showing the Name of Bank, Name of Account
                Holder and Account Number for your initial claim submission.{" "}
                <br />
                <br />
              </Typography>
              <SelectValidatorElement
                disabled={readOnly}
                value={bankId || ""}
                onChange={handleBankIdChange}
                name="bank_id"
                variant="standard"
                id="bank_id"
                label="Bank Name"
                autoComplete="[off]"
                validators={["required"]}
                suggestions={bankOptions}
                errorMessages={["Required"]}
              />
              <TextValidatorElement
                key="bank_account_holder_name"
                disabled={readOnly}
                autoComplete="[off]"
                variant="standard"
                fullWidth
                inputProps={{
                  maxLength: 50
                }}
                className={classes.textField}
                label="Bank Account Holder Name"
                onChange={handleChange("bank_account_holder_name")}
                name="bank_account_holder_name"
                value={bankAccountHolderName || ""}
                validators={["required"]}
                errorMessages={["Required"]}
              />
              <TextValidatorElement
                disabled={readOnly}
                key="bank_account_number"
                autoComplete="[off]"
                variant="standard"
                fullWidth
                inputProps={{
                  maxLength: 100
                }}
                className={classes.textField}
                label="Bank Account Number"
                onChange={handleChange("bank_account_number")}
                name="bank_account"
                value={bankAccountNumber || ""}
                inputComponent={AccountNumberFormat}
                validators={["required"]}
                errorMessages={["Required"]}
              />
            </div>
          );
        }
        return (
          <div>
            <Typography color="inherit" key="staticNotes">
              Please provide a copy of{" "}
              {paymentMethod === "GIRO_TO_COMPANY" ? "the company's" : "your"}{" "}
              bank account statement showing the Name of Bank, Name of Account
              Holder and Account Number for your initial claim submission.{" "}
              <br />
              <br />
            </Typography>
            <SelectValidatorElement
              disabled={readOnly}
              value={bankId || ""}
              onChange={handleBankIdChange}
              name="bank_id"
              variant="standard"
              id="bank_id"
              label="Bank Name"
              autoComplete="[off]"
              validators={["required"]}
              suggestions={bankOptions}
              errorMessages={["Required"]}
            />
            <TextValidatorElement
              key="bank_account_holder_name"
              disabled={readOnly}
              autoComplete="[off]"
              variant="standard"
              fullWidth
              inputProps={{
                maxLength: 50
              }}
              className={classes.textField}
              label="Bank Account Holder Name"
              onChange={handleChange("bank_account_holder_name")}
              name="bank_account_holder_name"
              value={bankAccountHolderName || ""}
              validators={["required"]}
              errorMessages={["Required"]}
            />
            <TextValidatorElement
              disabled={readOnly}
              key="bank_account_number"
              autoComplete="[off]"
              variant="standard"
              fullWidth
              inputProps={{
                maxLength: 100
              }}
              className={classes.textField}
              label="Bank Account Number"
              onChange={handleChange("bank_account_number")}
              name="bank_account"
              value={bankAccountNumber || ""}
              inputComponent={AccountNumberFormat}
              validators={["required"]}
              errorMessages={["Required"]}
            />
          </div>
        );
      }
      case Enums.PAYMENT_METHOD.CHEQUE:
        return (
          <Typography color="inherit" key={0}>
            If the claim is approved, a cheque will be mailed to your office
            along with your claims letter.
            <br />
            <br />
          </Typography>
        );
      case Enums.PAYMENT_METHOD.PAYNOW_TO_EMPLOYEE:
        return (
          <Typography color="inherit" key={0}>
            The claim reimbursement can only be made to the employee and will be
            paid via transfer to your PayNow NRIC/FIN ID by default. Please
            ensure that you have signed up for PayNow with your bank by linking
            it to your NRIC/FIN.
            <p>
              <b style={{ textDecoration: "underline" }}>
                To register for PayNow
              </b>
            </p>
            Log in to your bank’s internet or mobile banking account - Sign up
            for PayNow - Link your PayNow to your NRIC/FIN
            <br /> <br />
          </Typography>
        );
    }
  };

  const handleRadioChange = (event, type) => {
    let value = event.target.value;
    if (type == "gst_indicator") {
      let amount = null;
      if (value == "true") {
        amount = amountExcludeGst;
      } else if (value == "false") {
        amount = null;
      }
      setClaim(claim => ({
        ...claim,
        gst_indicator: value == "true" ? true : false,
        amount_exclude_gst: amount
      }));
    } else if (type == "medisave_indicator") {
      setClaim(claim => ({
        ...claim,
        medisave_indicator: value == "true" ? true : false
      }));
    } else if (type == "other_insurer_indicator") {
      setClaim(claim => ({
        ...claim,
        other_insurer_indicator: value == "true" ? true : false
      }));
    }
  };

  return (
    <div>
      <SelectValidatorElement
        disabled={readOnly}
        value={currency || ""}
        onChange={handleChange("currency")}
        name={"currency"}
        variant="standard"
        id={"currency"}
        label="Currency"
        autoComplete="[off]"
        validators={["required"]}
        suggestions={currencyData}
        errorMessages={["Required"]}
      />
      <TextValidatorElement
        disabled={readOnly}
        autoComplete="[off]"
        fullWidth
        className={classes.textField}
        variant="standard"
        label="Bill Amount"
        onChange={handleChange("bill_amount")}
        name="bill_amount"
        value={billAmount}
        inputComponent={NumberFormatCustom}
        validators={["required", "notNegative"]}
        helperText={
          "Please enter total net amount payable on the invoice (after CHAS and other subsidies, including GST if applicable)"
        }
        errorMessages={[
          "Required",
          "Only numbers and decimal point (.) are allowed in this field. Please remove any other special characters and alphabets."
        ]}
      />
      {/* Radio button for GST start */}

      <RadioButtonValidatorElement
        disabled={readOnly || currency !== "SGD"}
        autoComplete="[off]"
        fullWidth
        className={classes.textField}
        variant="standard"
        label="Please indicate if GST was included in the amount above. Please answer 'No' if it is being absorbed by the government"
        value={gstIndicator}
        inputComponent={NumberFormatCustom}
        validators={currency !== "SGD" ? [] : ["required"]}
        errorMessages={currency !== "SGD" ? [] : ["Required"]}
        handleRadioChange={handleRadioChange}
        handleChange={handleChange("amount_exclude_gst")}
        amountExcludeGst={amountExcludeGst || null}
        radioType={"gst_indicator"}
        color="primary"
        name="gstIndicator"
      />

      {/* Radio button for GST end */}

      {/* Radio button for medisave start */}
      <RadioButtonValidatorElement
        disabled={readOnly}
        autoComplete="[off]"
        fullWidth
        className={classes.textField}
        variant="standard"
        label="Does your bill show any deduction from Medisave?"
        value={medisaveIndicator}
        inputComponent={NumberFormatCustom}
        validators={["required"]}
        errorMessages={["Required"]}
        handleRadioChange={handleRadioChange}
        handleChange={handleChange("")}
        amountExcludeGst={null}
        radioType={"medisave_indicator"}
        color="primary"
        name="medisaveIndicator"
      />
      {/* Radio button for medisave end */}

      {/* Radio button for claiming policy start */}
      <RadioButtonValidatorElement
        disabled={readOnly}
        autoComplete="[off]"
        fullWidth
        className={classes.textField}
        variant="standard"
        label="Are you claiming from another insurer/policy (including Integrated Shield Plan)"
        value={otherInsurerIndicator}
        inputComponent={NumberFormatCustom}
        validators={["required"]}
        errorMessages={["Required"]}
        handleRadioChange={handleRadioChange}
        handleChange={handleChange("")}
        amountExcludeGst={null}
        radioType={"other_insurer_indicator"}
        color="primary"
        name="otherInsurerIndicator"
      />
      {/* Radio button for claiming policy end */}

      <TextValidatorElement
        disabled={readOnly}
        autoComplete="[off]"
        fullWidth
        className={classes.textField}
        variant="standard"
        label="Invoice No"
        onChange={handleChange("invoice_no")}
        name="invoice_no"
        infoLabel='For receipts without an invoice number, please enter "NA"'
        value={invoiceNo}
        validators={["required", "invoiceNumberCheck"]}
        errorMessages={[
          "Required",
          "Please ensure that only alphanumeric character. spaces ( ), and hyphen (-) are used in this field"
        ]}
      />

      <SelectValidatorElement
        disabled={readOnly}
        value={paymentMethod.length > 1 ? paymentMethod : defaultPaymentMethod}
        onChange={handleChange("payment_method")}
        variant="standard"
        name={"payment_method"}
        id={"payment_method"}
        label="Preferred Reimbursement Method"
        autoComplete="[off]"
        validators={["isSelectRequired"]}
        suggestions={paymentMethodData}
        errorMessages={["Required"]}
      />
      {renderPaymentDetails(
        shouldHide,
        paymentMethod,
        bankId,
        bankName,
        bankAccountNumber,
        bankAccountHolderName
      )}
      <TextValidatorElement
        disabled={readOnly}
        autoComplete="[off]"
        variant="standard"
        fullWidth
        inputProps={{
          maxLength: 10000
        }}
        className={classes.textField}
        label="Claimant's Remarks"
        onChange={handleChange("claimant_remarks")}
        name="claimant_remarks"
        value={claimantRemarks}
        validators={["specialCharacterRemark"]}
        errorMessages={[
          intl.formatMessage({
            id: "validator.invalid.specialCharacters"
          })
        ]}
      />
    </div>
  );
};
const styles = theme => ({
  boxHeader: {
    position: "relative",
    borderBottom: "1px solid #d3d3d3"
  },
  boxIcon: {
    backgroundColor: "#d87474",
    color: "white",
    padding: 8,
    display: "inline-block",
    verticalAlign: "middle"
  },
  boxIconApprove: {
    backgroundColor: "#18c494"
  },
  error: {
    color: "#f44336",
    marginLeft: "0px"
  },
  labelError: {
    color: "#f44336"
  }
});

export default injectIntl(withStyles(styles)(ClaimEditPaymentSection));
